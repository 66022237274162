<template>
  <div class="table_wrapper">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>
          General Settings
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" editable>
          Payment Settings
        </v-stepper-step>
        <v-stepper-step step="3" editable>
          Google Analytics
        </v-stepper-step>
        <v-stepper-step step="4" editable>
          Backup
        </v-stepper-step>
        <v-stepper-step step="5" editable>
          Cache
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="12">
                <v-text-field label="Site Title"></v-text-field>
              </v-col>

              <v-col cols="12">
                <p>Site Description</p>
                <v-stepper v-model="b1">
                  <v-stepper-header>
                    <v-stepper-step
                      :complete="b1 > index"
                      :step="index + 1"
                      editable
                      v-for="(item, index) in languages"
                      :key="index"
                    >
                      {{ item.name }}
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content
                      :step="index + 1"
                      v-for="(item, index) in languages"
                      :key="'tab-' + index"
                    >
                      <v-card class="p-3">
                        <v-text-field
                          :label="item.name + ' Description'"
                        ></v-text-field>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>

              <v-col cols="12">
                <p>Custom Scripts</p>
                <v-stepper v-model="c1">
                  <v-stepper-header>
                    <v-stepper-step
                      :complete="c1 > index"
                      :step="index + 1"
                      editable
                      v-for="(item, index) in languages"
                      :key="index"
                    >
                      {{ item.name }}
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content
                      :step="index + 1"
                      v-for="(item, index) in languages"
                      :key="'tab-' + index"
                    >
                      <v-card class="p-3">
                        <v-text-field
                          :label="item.name + ' Description'"
                        ></v-text-field>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-btn class="ml-3" color="green" dark>
                Save
              </v-btn>
              <v-btn color="primary" @click="e1 = 2">
                Next
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="12">
                <p>Payment Methods</p>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="12">
                <p>Google Analytics</p>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="12">
                <p>Backup</p>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-card class="p-3">
            <v-row align="center">
              <v-col cols="12">
                <p>Cache</p>
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "SiteSettings",
  data() {
    return {
      e1: 1,
      b1: 1,
      c1: 1,
      overlay: false,
      languages: null,
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    getLang() {
      const url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=" +
        this.page +
        "&per_page=" +
        this.per_page +
        "&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.languages = res.data.data.data;
          console.log(this.languages);
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getLang();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
